import React, { useState, useEffect, useRef } from 'react';

const NFTTransferCard = ({ currentUser, selectedNFTs, handleTransferNFTs, userNFTs, handleSelectNFT, sharkSwapTokenBalance, sharkTokensFetched }) => {
    const [errorMessage, setErrorMessage] = useState("");
    const [loadingNFTs, setLoadingNFTs] = useState(true); // Track loading status
    const [showScrollIndicator, setShowScrollIndicator] = useState(false); // Track if scroll indicator is needed
    const creatorPublicKey = process.env.REACT_APP_MINTER_PUBLIC_KEY; // The specific public key to filter by
    const nftContainerRef = useRef(null); // Ref to the scrollable container

    // Function to handle scrolling down in the NFT container
    const handleScrollDown = () => {
        const container = nftContainerRef.current;
        if (container) {
            container.scrollBy({ top: 100, behavior: 'smooth' }); // Scrolls 100px down
        }
    };

    // Check if NFTs are still loading and update the state
    useEffect(() => {
        if (userNFTs && userNFTs.NFTsMap !== undefined) {
            setLoadingNFTs(false); // Stop loading once userNFTs is populated
        }
    }, [userNFTs]);

    useEffect(() => {
        if (selectedNFTs.length * 1000 <= sharkSwapTokenBalance) {
            setErrorMessage("");
        }
    }, [selectedNFTs, sharkSwapTokenBalance]);

    // Filter NFTs by the specified creator public key
    const filteredNFTs = userNFTs && userNFTs.NFTsMap
        ? Object.keys(userNFTs.NFTsMap).filter(key => {
            const postEntryResponse = userNFTs.NFTsMap[key].PostEntryResponse;
            console.log(postEntryResponse);
            return postEntryResponse.ProfileEntryResponse?.PublicKeyBase58Check === creatorPublicKey &&
                !postEntryResponse.Body.startsWith("Shark Gang Lottery") &&
                !postEntryResponse.Body.includes("Shark Gang - Mint Pass") &&
                postEntryResponse.PostExtraData["Collection Name"] == "Shark Gang";
        })
        : [];

    // Check if the container has overflow and should show the scroll indicator
    useEffect(() => {
        const container = nftContainerRef.current;
        if (container) {
            const isOverflowing = container.scrollHeight > container.clientHeight;
            setShowScrollIndicator(isOverflowing);
        }
    }, [filteredNFTs]);

    return (
        <div className="p-2 flex flex-col text-center overflow-x-hidden bg-white bg-opacity-95 mx-2 rounded-2xl border-2 border-slate-200 shadow w-full">
            <div className="flex flex-col gap-4 items-center justify-center p-4 rounded-2xl mt-2">
                {sharkTokensFetched && sharkSwapTokenBalance > 1000 && <div className="font-bold text-center text-xl">Swap SharkGang NFTs for SharkGang Tokens. Each NFT is worth 1000 Tokens!</div>}
            </div>

            {/* Error Message Display */}
            {errorMessage && <div className="text-red-500 font-bold">{errorMessage}</div>}

            {/* Display message if SharkSwap doesn't have enough tokens */}
            {sharkTokensFetched && sharkSwapTokenBalance < 1000 && (
                <div className="text-red-500 font-bold mb-8">Sorry, SharkSwap does not have enough tokens to swap. Please try again later.</div>
            )}

            {/* Current User Logic */}
            {sharkSwapTokenBalance > 1000 ? (
            <>
            {currentUser ? (
                loadingNFTs? (
                    <div className="text-gray-500">Loading NFTs...</div> // Show loading while NFTs are being fetched
                ) : filteredNFTs.length > 0 ? ( // Show NFTs if the filtered list has NFTs
                    <>
                    <div
                        ref={nftContainerRef} // Attach the ref to the scrollable container
                        className="grid grid-flow-row auto-rows-max grid-cols-[repeat(auto-fill,minmax(100px,1fr))] gap-4 pb-0 nft-display max-w-full max-h-[400px] overflow-y-auto"
                    >
                        {filteredNFTs.map((key, index) => {
                            const postEntryResponse = userNFTs.NFTsMap[key].PostEntryResponse;
                            const nftEntryResponses = userNFTs.NFTsMap[key].NFTEntryResponses;
                            const isSelected = selectedNFTs.some(nft => nft.NFTPostHashHex === postEntryResponse.PostHashHex && nft.SerialNumber === nftEntryResponses[0]?.SerialNumber);

                            return (
                                <div
                                    key={index}
                                    className={`w-full h-auto pb-2 bg-gray-800 rounded-xl shadow-lg transform ${isSelected ? "scale-95" : ""}`}
                                    onClick={() => {
                                        if (isSelected) {
                                            // Deselect the NFT
                                            handleSelectNFT(postEntryResponse.PostHashHex, nftEntryResponses[0]?.SerialNumber, postEntryResponse.ImageURLs[0]);
                                            setErrorMessage("");
                                        } else {
                                            // Check if adding this NFT exceeds the balance
                                            if ((selectedNFTs.length + 1) * 1000 <= sharkSwapTokenBalance) {
                                                handleSelectNFT(postEntryResponse.PostHashHex, nftEntryResponses[0]?.SerialNumber, postEntryResponse.ImageURLs[0]);
                                                setErrorMessage("");
                                            } else {
                                                setErrorMessage("Sorry, you cannot select more NFTs than the available SharkSwap token balance.");
                                            }
                                        }
                                    }}
                                >
                                    {postEntryResponse && postEntryResponse.ImageURLs && postEntryResponse.ImageURLs[0] && (
                                        <>
                                            <div className="relative w-full h-24">
                                                <img className="w-full h-full object-cover nft-card-image" src={postEntryResponse.ImageURLs[0]} alt={`NFT ${index}`} />
                                                {isSelected && (
                                                    <div className="absolute top-2 right-2 bg-white rounded-full">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                                        </svg>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="text-white font-bold truncate mt-1 text-xs">{postEntryResponse.ProfileEntryResponse?.Username}</div>
                                            <div className="text-gray-400 text-xs truncate max-h-8 overflow-hidden">{postEntryResponse.Body}</div>
                                        </>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                    {/* Conditionally show scroll indicator */}
                    {showScrollIndicator && (
                        <div className="flex justify-center mt-2">
                            <button onClick={handleScrollDown} className="focus:outline-none">
                                <svg className="w-6 h-6 text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                                </svg>
                            </button>
                        </div>
                    )}
                    </>
                ) : (
                    <div className="text-black">No NFTs found.</div> // Display when no NFTs from the specific public key are found
                )
            ) : (
                <div className="text-red-500 font-bold mb-4">Log in to view and transfer your NFTs.</div>
            )}

            {/* Transfer Section */}
            <div className="w-full flex flex-col items-center justify-center rounded-xl py-4 mt-4">
                <div className="w-full bg-white border-2 rounded-xl p-2 mb-2">
                    <div className="flex flex-wrap gap-2 items-center">
                        <span className="text-black font-bold block py-2 text-sm">NFTs:</span>
                        {selectedNFTs.map((nft, index) => {
                            const imageUrl = nft.imageURL;

                            return (
                                <div key={index} className="relative h-8 w-8 bg-white rounded-full image-container border-2 border-white flex-shrink-0">
                                    {imageUrl ? (
                                        <img
                                            src={imageUrl}
                                            className="rounded-full"
                                            alt={`Selected NFT ${index}`}
                                        />
                                    ) : (
                                        <div className="h-full w-full bg-gray-300 rounded-full"></div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </div>

                {/* Amount Calculation */}
                <div className="w-full flex justify-center items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-black" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M10 3a1 1 0 00-1 1v10a1 1 0 102 0V4a1 1 0 00-1-1z" clipRule="evenodd" />
                        <path fillRule="evenodd" d="M4.293 9.707a1 1 0 011.414 0L10 14.086l4.293-4.379a1 1 0 111.414 1.414l-5 5.086a1 1 0 01-1.414 0l-5-5.086a1 1 0 010-1.414z" clipRule="evenodd" />
                    </svg>
                </div>

                {/* Token Info */}
                <div className="w-full flex justify-between items-center bg-white border-2 rounded-xl p-2 py-3 mt-2">
                    <span className="text-black font-bold text-sm py-1">RECEIVE:</span>
                    <div className="flex items-center">
                        <div className="w-12 text-center">{selectedNFTs.length * 1000}</div>
                        <div>Tokens</div>
                    </div>
                </div>

                {/* Transfer Button */}
                <button 
                  type="button"
                  onClick={handleTransferNFTs} 
                  className="custom-button mt-4" 
                  disabled={!currentUser || sharkSwapTokenBalance < 1000 || selectedNFTs.length === 0}
                >
                  {currentUser ? "Transfer NFTs" : "Connect Wallet"}
                </button>
            </div>

            </>
            ) : null}
        </div>
    )
};

export default NFTTransferCard;
